import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { toast } from 'react-toastify'
import { gql, useMutation, useQuery } from '@apollo/client'
import { graphql, navigate, useStaticQuery } from 'gatsby'

import client from '../gatsby-plugin-apollo/client'
import { REFRESH_TOKEN } from '../graphql/auth'
import { useSearch } from '../hooks/search'
import { useWindowSize } from '../hooks/window'
import { getSubscription } from '../utils/users'

const AppContext = createContext({})

export const GET_USER = gql`
  query getUser {
    viewer {
      id
      databaseId
      name
      firstName
      lastName
      email
      nomAbo
      typeAbo
      userAdress
      userAdress2
      userCity
      userCountry
      userZip
      stripeCustomerId
      renouvellementAbo
      methode
      derniereTransaction
      subscriberData {
        avatarImage
        variantAvatar
      }
      redactor {
        img {
          sourceUrl
        }
      }
    }
  }
`

export function AppProvider({ children }) {
  const [user, setUser] = useState(null)

  const {
    data: userData,
    loading: userLoading,
    error: userError,
    refetch: userRefetch,
  } = useQuery(GET_USER, {
    skip: typeof localStorage === 'undefined' || !localStorage.getItem('token'),
    onCompleted: (userData) => {
      const { isSubscriber, endSubscription } = getSubscription(
        userData?.viewer || {}
      )

      if (userData?.viewer) {
        setUser({
          ...userData.viewer,
          endSubscription,
          isSubscriber,
        })
      } else {
        setUser(null)
      }
    },
    onError: () => setUser(null),
  })

  const search = useSearch()
  const [searchResultOpen, setSearchResultOpen] = useState(false)
  const [cartOpen, setCartOpen] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  const [heights, setHeights] = useState({})
  const windowSize = useWindowSize()

  useEffect(() => {
    if (searchResultOpen) {
      setMenuOpen(false)
      setCartOpen(false)
    }
  }, [searchResultOpen])

  useEffect(() => {
    if (cartOpen) {
      setSearchResultOpen(false)
      setMenuOpen(false)
    }
  }, [cartOpen])

  useEffect(() => {
    if (menuOpen) {
      setSearchResultOpen(false)
      setCartOpen(false)
    }
  }, [menuOpen])

  useEffect(() => {
    const [navbar, footer] = [
      document.querySelector('#navbar')?.offsetHeight,
      document.querySelector('#footer')?.offsetHeight,
    ]

    setHeights({ navbar, footer, ui: navbar + footer })
  }, [windowSize])

  const [updateUser, updateUserMutation] = useMutation(
    gql`
      mutation updateUser(
        $id: ID!
        $firstName: String
        $lastName: String
        $avatarImage: String
        $variantAvatar: String
        $email: String
        $userAdress: String
        $userAdress2: String
        $userCity: String
        $userZip: String
        $userCountry: String
      ) {
        updateUser(
          input: {
            id: $id
            firstName: $firstName
            lastName: $lastName
            avatarImage: $avatarImage
            variantAvatar: $variantAvatar
            email: $email
            userAdress: $userAdress
            userAdress2: $userAdress2
            userCity: $userCity
            userZip: $userZip
            userCountry: $userCountry
          }
        ) {
          clientMutationId
        }
      }
    `,
    { refetchQueries: [GET_USER] }
  )

  const [
    refresh,
    { data: refreshData, error: refreshError, loading: refreshLoading },
  ] = useMutation(REFRESH_TOKEN)

  const {
    site: {
      siteMetadata: { buildDate },
    },
    allWp: {
      nodes: [
        {
          zc: {
            FirstMonthOffer: { firstmonthoffer: discountsEnabled },
            globalSettings,
            Trial,
            SubscriptionFrequency,
            announces: { announces },
          },
        },
      ],
    },
    allWpMenu,
  } = useStaticQuery(graphql`
    query SiteSettings {
      allWp {
        nodes {
          zc {
            FirstMonthOffer {
              firstmonthoffer
            }
            Trial {
              trial
            }
            SubscriptionFrequency {
              frequency
            }
            globalSettings {
              preheader
              socialsNetworks {
                name
                url
              }
              stripeCustomerPortalUrl
              contactEmail
              comments {
                buttonLabel
                defaultCount
                incentiveLabel
              }
              callWritingEmail
              brevoNewsletterId
            }
            announces {
              announces {
                color
                hoverColor
                hoverTextColor
                label
                newsletterText
                textColor
                url {
                  url
                }
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          buildDate
        }
      }
      allWpMenu {
        nodes {
          name
          menuItems {
            nodes {
              label
              uri
            }
          }
        }
      }
    }
  `)

  const _updateUser = async (input, config = {}) => {
    try {
      const payload = { ...input, id: user.databaseId }
      await updateUser({ variables: payload })

      if (Object.keys(updateUserMutation?.error || {}).length > 0) {
        throw new Error()
      }

      if (config.successMessage !== null) {
        toast(
          config.successMessage ||
            'La modification de vos données personnelles a bien été prise en compte',
          { type: 'success' }
        )
      }
    } catch (err) {
      toast(
        config.errorMessage || "Nous n'avons pas pu mettre à jour vos données",
        {
          type: 'error',
        }
      )
    }
  }

  const getStripeSession = useCallback(
    async (cart, metadata = {}) => {
      const { email, typeAbo } = user || {}
      const meta = {
        discountsEnabled,
        email,
        ...metadata,
      }
      // disable discounts for first month
      if (typeAbo?.length) {
        meta.isSubscriber = true
      }
      const result = await fetch('/api/stripe', {
        method: 'POST',
        body: JSON.stringify({
          cart,
          metadata: meta,
        }),
      })
      const { id } = await result.json()
      return id
    },
    [discountsEnabled, user]
  )

  const menus = {
    header: allWpMenu.nodes.find((node) => node.name === 'header').menuItems
      .nodes,
    footer: {
      ...allWpMenu.nodes
        .filter((node) => node.name !== 'header')
        .reduce(
          (acc, cur) => ({ ...acc, [cur.name]: cur.menuItems.nodes }),
          {}
        ),
    },
  }

  // access token may be expired, let's refresh
  // if not fetching user or already fetching new token etc.
  if (
    !user &&
    !userLoading &&
    !refreshLoading &&
    !refreshError &&
    !refreshData &&
    typeof localStorage !== 'undefined'
  ) {
    const refreshToken = localStorage.getItem('refreshToken')
    if (refreshToken) {
      console.info('no user, try to refresh auth token')
      refresh({
        variables: { refreshToken },
        onError: () => {
          setTokens({})
        },
        onCompleted: (data) => {
          setTokens(data.refreshToken)
        },
      })
    }
  }

  const logout = (config = {}) => {
    setUser(null)
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    client.resetStore()
    userRefetch()
    navigate('/')
  }

  const setTokens = ({ authToken, refreshToken } = {}) => {
    if (authToken) {
      localStorage.setItem('token', authToken)
      if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken)
      }
      userRefetch()
    } else {
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      setUser(null)
    }
  }

  const value = {
    globalSettings,
    trialEnabled: Trial?.trial === true,
    announces,
    SubscriptionFrequency,
    setTokens,
    logout,
    user: userData?.viewer || user,
    cartOpen,
    setCartOpen,
    menuOpen,
    setMenuOpen,
    loading: userLoading,
    error: userError,
    getStripeSession,
    buildDate,
    menus,
    updateUser: _updateUser,
    updateUserMutation,
    userRefetch,
    heights,
    search,
    searchResultOpen,
    setSearchResultOpen,
    discountsEnabled,
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

const useApp = () => useContext(AppContext)

export default useApp
